<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">报价基本信息</el-col>
    </el-row>
    <el-form ref="Form" :model="supplierform" :rules="rules" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="报价单号" prop="offerCode">
            <el-input v-model="supplierform.offerCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商" prop="vendorId">
            <el-select
              v-model="supplierform.vendorId"
              value-key="id"
              clearable
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="(outboundDetailTable.length===0)?false:true"
            >
              <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="币种" prop="currencyType">
            <el-select
              v-model="supplierform.currencyType"
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="fordisabled"
            >
              <!--  <el-option label="美元" value="1" />
              <el-option label="人民币" value="2" /> -->
              <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态" prop="offerStatus">
            <el-select v-model="supplierform.offerStatus" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_OFFER_STATUS')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报价人" prop="createByName">
            <!-- <el-select v-model="supplierform.createByName" clearable filterable :placeholder="$t('page.selectPlaceholder')" :disabled="fordisabled">
              <el-option
                v-for="item in employeeOptions"
                :key="item.id"
                :label="item.employeeName"
                :value="item.employeeName"
              />
            </el-select> -->
            <el-input v-model="supplierform.createByName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报价时间" prop="createTime">
            <el-date-picker
              v-model="supplierform.createTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="请选择"
              default-value
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="supplierform.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item ref="uploadRef" label="附件" prop="lfoafilelist" :rules="{ required: true, message: this.$t('page.required'), trigger: 'change' }">
            <el-upload
              ref=""
              :headers="uploadHeaders"
              action=""
              class="upload-demo"
              :file-list="supplierform.lfoafilelist"
              :limit="5"
              show-file-list
              :http-request="handleUploadFile"
              :before-upload="beforeUpload"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :on-success="lfoahandleSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>

          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">报价明细</span>
        <el-button type="primary" class="ml-2" @click="addWavePicking()">批量新增</el-button>
        <el-button type="primary" @click="handleDelete()">删除</el-button>
        <el-button type="primary" @click="allclick()">一键赋值</el-button>
      </el-col>
    </el-row>
    <el-table
      id="myTable"
      ref="uploadTableDataRef"
      class="mb-3"
      :data="outboundDetailTable"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
      @cell-dblclick="tabClick"
    >
      >
      <el-table-column type="selection" width="70" />
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="productDevelopCode" label="产品开发编号" width="140" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '产品开发编号'">
            <el-input
              v-model="scope.row.productDevelopCode"
              maxlength="300"
              placeholder="请输入"
              size="mini"
              @blur="inputBlur"
              @input="inputChange($event,scope.row.index,'productDevelopCode')"
            />
          </span>
          <span v-else>{{ scope.row.productDevelopCode }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="imgUrl" align="center" sortable label="图片" width="160">
        <template slot-scope="scope">
          <img :src="scope.row.imgUrl" alt="暂无图片" style="width: 50px;height: 50px">
        </template>
      </el-table-column>

      <el-table-column
        prop="style"
        label="规格型号"
        width="260"
        align="center"
        :sortable="true"
        :sort-by="['style', 'color', 'sizeSort']"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column prop="isFlock" label="大底是否植绒" width="140" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '大底是否植绒'">
            <el-input
              v-model="scope.row.isFlock"
              maxlength="300"
              placeholder="请输入"
              size="mini"
              @blur="inputBlur"
              @input="inputChange($event,scope.row.index,'isFlock')"
            />
          </span>
          <span v-else>{{ scope.row.isFlock }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="packMethod" label="包装方式" width="140" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '包装方式'">
            <el-input v-model="scope.row.packMethod" maxlength="300" placeholder="请输入" size="mini" @blur="inputBlur" />
          </span>
          <span v-else>{{ scope.row.packMethod }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="offerPrice" label="单价" width="140" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '单价'">
            <el-input v-model="scope.row.offerPrice" maxlength="300" placeholder="请输入" size="mini" @blur="inputBlur" />
          </span>
          <span v-else>{{ scope.row.offerPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isExcludingTax" label="是否含税" width="140" align="center" sortable>
        <template slot-scope="scope">
          <div v-if="scope.row.index === tabClickIndex && tabClickLabel === '是否含税'">是否含税
            <el-radio-group v-model="scope.row.isExcludingTax">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </div>
          <div v-else>
            <span v-if="scope.row.isExcludingTax==0">是</span>
            <span v-if="scope.row.isExcludingTax==1">否</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="effectiveStartDate" label="生效开始日期" width="180" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '生效开始日期'">
            <el-date-picker
              v-model="scope.row.effectiveStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              maxwidth="138px"
              placeholder="请选择"
              default-value
              @blur="inputBlur"
            />
          </span>
          <span v-else>{{ scope.row.effectiveStartDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="effectiveEndDate" label="生效结束日期" width="180" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && tabClickLabel === '生效结束日期'">
            <el-date-picker
              v-model="scope.row.effectiveEndDate"
              value-format="yyyy-MM-dd"
              type="date"
              maxwidth="138px"
              placeholder="请选择"
              default-value
              @blur="inputBlur"
            />
          </span>
          <span v-else>{{ scope.row.effectiveEndDate }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 一键赋值 -->
    <el-dialog title="一键赋值" :visible.sync="editVisible" width="750px">
      <el-form ref="editForm" :model="editForm" label-width="120px" :rules="editFormRules">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="产品开发编号" prop="productDevelopCode">
              <el-input v-model="editForm.productDevelopCode" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="大底是否植绒" prop="isFlock">
              <el-input v-model="editForm.isFlock" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="包装方式" prop="packMethod">
              <el-input v-model="editForm.packMethod" />
            </el-form-item>
          </el-col>

          <el-col :span="7">
            <el-form-item label="单价" prop="offerPrice">
              <el-input v-model="editForm.offerPrice" oninput="value=value.replace(/[^0-9.]/g,'')" />
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin-left:3px;">
            <el-checkbox v-model="editForm.isExcludingTax" true-label="0" false-label="1">含税</el-checkbox>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="生效开始日期" prop="effectiveStartDate">
              <el-date-picker
                v-model="editForm.effectiveStartDate"
                type="date"
                placeholder="请选择"
                value-format="yyyy-MM-dd"
                :picker-options="start"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生效结束日期" prop="effectiveStartDate">
              <el-date-picker
                v-model="editForm.effectiveEndDate"
                type="date"
                placeholder="请选择"
                :picker-options="end"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer">
        <el-button :loading="loading" type="primary" @click="handleEditSubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 确认提交进审批流 -->
    <el-dialog :visible.sync="dialogVisible" title="提示" width="30%">
      <span>提交计划单会进入审批流，您确认提交吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="Loadingcommit" @click="saveplanorder">确 定</el-button>
      </span>
    </el-dialog>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <!-- <el-button @click="cancle()">{{ $t('title.cancel') }}</el-button> -->
      <el-button v-if="!isTransported" :loading="Loadingsave" type="primary" @click="save('ruleForm')">保存</el-button>
      <el-button v-if="!isTransported" type="primary" @click="onSubmit">提交</el-button>
    </el-row>
  </div>
</template>
<script>
import { uploadByPreUrlApi, getVendorOfferId, queryVendorList, vendorOfferSave, vendorOfferSubmit, oneKey } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

import axios from 'axios'
import { omit } from 'lodash'

export default {
  mixins: [commodityInfoDict],
  data() {
    return {
      start: {
        disabledDate: (time) => {
          const beginDateVal = this.editForm.effectiveEndDate
          if (beginDateVal) {
            return time.getTime() > beginDateVal
          }
        }
      },
      end: {
        disabledDate: (time) => {
          const beginDateVal = this.editForm.effectiveStartDate
          if (beginDateVal) {
            return time.getTime() < beginDateVal
          }
        }
      },
      loading: false,
      editVisible: false,
      dialogVisible: false,
      fordisabled: false, // 禁用文本框
      isDisabled: false,
      importdata: [],
      importdialog: false,
      importDetaildialog: false,
      file: '',
      fileList: [],
      importDialog: false,
      uploadTableVisible: false,
      supplierform: {
        offerStatus: '0',
        currencyType: '',
        offerCode: '',
        createTime: '',
        createByName: '',
        vendorId: '',
        vendorName: '',
        fileKeyList: [],
        lfoafilelist: []
      },
      editForm: {
        effectiveStartDate: '',
        effectiveEndDate: '',
        isExcludingTax: 1
      },
      sidvoList: [],
      ids: [],
      vendorOptions: [],
      employeeOptions: [],
      styleOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      outboundDetailTable: [],
      Loadingsave: false,
      Loadingcommit: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      rules: {
        currencyType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      editFormRules: {
        offerPrice: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      },
      tabClickIndex: '',
      tabClickLabel: '',
      vendorId: '',
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      }

    }
  },
  computed: {
    isTransported() {
      return this.supplierform.offerStatus === '草稿' || this.supplierform.offerStatus === '审批中'
    },
    user() {
      return this.$store.getters.authorities
    }
  },
  watch: {
    'editVisible'(val) {
      if (!val) {
        this.editForm = this.$options.data.call(this).editForm
        this.$refs['editForm'].resetFields()
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.disabled || this.$route.query.venderId) {
      this.fordisabled = true
      this.supplierform.currencyType = this.$route.query.currencyType
    }
    if (this.$route.query.vendorId) {
      this.isDisabled = true
    }
    this._queryVendorList()
    this._getVendorOfferId()
    // 默认全选
    this.$nextTick(() => {
      for (let i = 0; i < this.outboundDetailTable.length; i++) {
        this.$refs.uploadTableDataRef.toggleRowSelection(
          this.outboundDetailTable[i],
          true
        )
      }
    })
  },
  destroyed() {
    if (this.$route.name !== 'AllAddedQuote') {
      sessionStorage.removeItem('indatas')
    }
  },
  methods: {
    initDatas() {
      this.outboundDetailTable = sessionStorage.getItem('indatas') ? JSON.parse(sessionStorage.getItem('indatas')) : []
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
      if (this.$route.query.vendorId) {
        this.supplierform.vendorId = this.$route.query.vendorId
      }
    },

    allclick() {
      const data = this.$refs.uploadTableDataRef.selection

      // let pairs = 0
      if (data.length > 0) {
        this.editVisible = true
      }
      this.$refs.uploadTableDataRef.expectDeliveryDate = true
    },
    handleEditSubmit() {
      this.$refs['editForm'].validate(async(valid) => {
        if (valid) {
          let obj = {}
          const { vendorName } = this.vendorOptions.find(item => item.id === this.supplierform.vendorId) || []
          obj = Object.assign({}, this.editForm, { vendorName })
          const { code, msg, datas } = await oneKey(obj)
          code === 0 && this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          const { effectiveStartDate, excludingTaxPrice, effectiveEndDate, offerPrice, isExcludingTax, isFlock, packMethod, productDevelopCode, purchasePrice } = datas
          this.outboundDetailTable.map(e => {
            this.$set(e, 'offerPrice', offerPrice)
            this.$set(e, 'isExcludingTax', isExcludingTax)
            this.$set(e, 'excludingTaxPrice', excludingTaxPrice)
            this.$set(e, 'isFlock', isFlock)
            this.$set(e, 'packMethod', packMethod)
            this.$set(e, 'productDevelopCode', productDevelopCode)
            this.$set(e, 'purchasePrice', purchasePrice)
            this.$set(e, 'effectiveStartDate', effectiveStartDate)
            this.$set(e, 'effectiveEndDate', effectiveEndDate)
          })
          this.editVisible = false
        } else {
          return false
        }
      })
    },
    // 修改查询
    async _getVendorOfferId(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas, datas: { currencyType, offerStatus }} = await getVendorOfferId(id)

        Object.assign(this.supplierform, datas, { fileKeyList: [], currencyType, offerStatus: offerStatus + '' })
        if (datas.fileVOList) {
          this.supplierform.lfoafilelist = datas.fileVOList.map(item => {
            this.supplierform.fileKeyList.push(item.fileKey)
            return { url: item.fileKey, name: item.fileName, id: item.fileId }
          })
        }

        this.outboundDetailTable = sessionStorage.getItem('indatas') ? JSON.parse(sessionStorage.getItem('indatas')) : datas.details

        this.$nextTick(() => {
          for (let i = 0; i < this.outboundDetailTable.length; i++) {
            this.$refs.uploadTableDataRef.toggleRowSelection(
              this.outboundDetailTable[i],
              true
            )
          }
        })
      } else {
        this.initDatas()
      }
    },
    // 数量
    getTotalNumber(ref, row) {
      let outPairsBoo = true
      if (row.expectPairs <= 0) outPairsBoo = false
      this.$refs[ref].toggleRowSelection(row, outPairsBoo)
    },
    // 删除明细
    handleDelete() {
      const data = this.$refs.uploadTableDataRef.selection
      data.map(e => {
        this.ids.push(e.id)
        var index = this.outboundDetailTable.findIndex((i) => {
          return e.style === i.style && e.color === i.color && e.size === i.size
        })
        if (index !== -1) {
          this.outboundDetailTable[index]
          this.outboundDetailTable.splice(index, 1)
          console.log(this.outboundDetailTable[index])
        }
      })
      if (this.outboundDetailTable.length === 0) {
        this.isDisabled = false
      }
      sessionStorage.setItem('indatas', JSON.stringify(this.outboundDetailTable))
    },

    handleSelectionChange(val) {
      this.sidvoList = val
    },

    // 保存供应商报价
    save() {
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条计划明细保存',
          type: 'warning'
        })
        return false
      }
      let styleValid = true
      this.$refs['Form'].validate((valid) => {
        if (!valid) {
          styleValid = false
          return false
        } else {
          const val = this.vendorOptions.find(e => e.id === this.supplierform.vendorId ? e.vendorName : '')
          const vo = {}

          Object.assign(vo, omit(this.supplierform, ['lfoafilelist', 'fileVOList']), { vendorName: val.vendorName }, { details: this.sidvoList })
          // vo.deleteIds = this.ids

          const { id } = this.$route.query
          if (id) {
            vo.id = id
            // console.log(vo)
            this._vendorOfferSave(vo)
          } else {
            // console.log(vo)
            this._vendorOfferSave(vo)
          }
        }
      })
      if (!styleValid) return
    },
    // 保存供应商报价
    async _vendorOfferSave(vo) {
      try {
        this.Loadingsave = true
        const { code, msg } = await vendorOfferSave(vo)

        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loadingsave = false
        }
      } finally {
        this.Loadingsave = false
      }
    },
    // 提交
    onSubmit() {
      this.dialogVisible = true
    },
    saveplanorder() {
      if (!this.sidvoList.length) {
        this.$message({
          message: '请至少选择一条计划明细保存',
          type: 'warning'
        })
        return false
      }

      let styleValid = true
      this.$refs['Form'].validate(async(valid) => {
        if (!valid) {
          styleValid = false
          return false
        } else {
          const vendorName = this.vendorOptions.find(e => e.id === this.supplierform.vendorId)?.vendorName

          const vo = {}
          vo.details = this.sidvoList
          Object.assign(vo, { details: this.sidvoList }, omit(this.supplierform, ['lfoafilelist', 'fileVOList']), { vendorName })
          const { id } = this.$route.query
          if (id) {
            vo.id = id
            this._vendorOfferSubmit(vo)
          } else {
            this._vendorOfferSubmit(vo)
          }
        }
      })
      if (!styleValid) return
    },
    // 提交采购计划
    async _vendorOfferSubmit(vo) {
      try {
        this.Loadingcommit = true
        const { code, msg } = await vendorOfferSubmit(vo)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loadingcommit = false
        }
      } finally {
        this.dialogVisible = false
        this.Loadingcommit = false
        this.supplierform.attachmentUrl = ''
      }
    },
    lfoahandleSuccess(response) {
      response && this.supplierform.fileKeyList.push(response?.objectName)
    },
    handleExceed() {
      this.$message.warning('当前限制选择 5 个文件')
    },
    handleRemove(val) {
      const index = this.supplierform.lfoafilelist.findIndex((item) => item.uid === val.uid)
      this.supplierform.lfoafilelist.splice(index, 1)
      this.supplierform.fileKeyList.splice(index, 1)
    },

    async handleUploadFile(options) {
      const { name: fileName, size: kbSize } = options.file
      const params = {
        fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      }
      const { datas } = await uploadByPreUrlApi(params)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, callback, signature },
        { file: options?.file }
      )
      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set(`${key}`, form[key]))
      const { data: { datas: response }} = await axios({
        method: 'post',
        url,
        data: formData
      })

      response && options.onSuccess(response) // 成功之后取值
      this.supplierform.lfoafilelist.push(options.file)
      this.$refs.uploadRef.clearValidate()
    },
    // 导入
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      } else {
        return true
      }
    },

    // 修改
    addWavePicking(row, disabled) {
      sessionStorage.setItem('indatas', JSON.stringify(this.outboundDetailTable))
      this.$router.push({
        path: 'all-added-quote',
        append: true,
        query: { 'id': this.$route.query.id, 'disabled': this.$route.query.disabled, 'vendorId': this.supplierform.vendorId, 'currencyType': this.supplierform.currencyType }
      })
    },
    // table点击 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      this.tabClickIndex = row.index
      this.tabClickLabel = column.label
    },
    // 失去焦点初始化
    inputBlur(row, event, column) {
      this.tabClickIndex = null
      this.tabClickLabel = ''
    },
    tableRowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row
      row.index = rowIndex
    },
    inputChange(e, index, name) {
      this.$forceUpdate()
      this.outboundDetailTable[index][name] = e
      this.$set(this.outboundDetailTable, index, this.outboundDetailTable[index])
    }

  }
}
</script>

